import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduksjon`}</h2>
    <p>{`Det er viktig å kvalitetssikre implementeringen av Designsystemets komponenter i kontekst av siden.
Dette betyr at selv om komponentene er grunnleggende utviklet for å imøtekomme WCAG-standarden, vil det være nødvendig å sjekke at alt fungerer som det skal når de ulike komponentene benyttes.
På denne siden finner du en oversikt og gjennomgang av relevante WCAG testkriteria.
Ikke-relevante kriterier vil foreløpig ikke være inkludert på denne siden.`}</p>
    <p>{`Hver enkelt komponent vil lenke direkte til testkriterie de må testes for.`}</p>
    <h2>{`Prinsipp 1: Mulig å oppfatte`}</h2>
    <h3>{`Ikke-tekstlig innhold (1.1.1)`}</h3>
    <ul>
      <li parentName="ul">{`Inspiser koden.`}</li>
      <li parentName="ul">{`Hvis det ikke-tekstlige innholdet vil gi nyttig informasjon med kompenserende teknologi
som for eksempel skjermlesere, sjekk at det har tekstalternativ eller navn spesifisert i
koden (alt-tekst, aria-label, aria-labelledby, title, label, ...). Standard
HTML-punktlister trenger for eksempel ikke tekstalternativ for at kulepunktene skal presenteres riktig
med kompenserende teknologi. Se bare at standard ul- og li-elementer er brukt.`}</li>
      <li parentName="ul">{`Hvis det ikke-testlige innholdet er pynt eller ikke vil gi nyttig informasjon med
kompenserende teknologi, sjekk at det er tilstrekkelig skjult for kompenserende teknologi
i koden (alt-tekst som står tom, aria-hidden true, ...).`}</li>
      <li parentName="ul">{`Aktiver skjermleser.`}</li>
      <li parentName="ul">{`For ikke-tekstlig innhold som skal fanges opp av kompenserende teknologi, test at
tekstalternativet eller navnet leses opp av skjermleseren. For standard HTML-punktlister,
sjekk at kulepunktene leses opp (typisk sier skjermleser for eksempel punkttegn).`}</li>
      <li parentName="ul">{`For innhold som skal være skjult, test at dette ikke leses opp.`}</li>
    </ul>
    <h3>{`Informasjon og relasjoner (1.3.1)`}</h3>
    <h3>{`Meningsfylt rekkefølge (1.3.2)`}</h3>
    <h3>{`Bruk av farge (1.4.1)`}</h3>
    <h3>{`Kontrast (1.4.3)`}</h3>
    <h3>{`Endring av tekststørrelse (1.4.4)`}</h3>
    <h3>{`Bilde av tekst (1.4.5)`}</h3>
    <h2>{`Prinsipp 2: Mulig å betjene`}</h2>
    <h3>{`Tastatur (2.1.1)`}</h3>
    <h3>{`Ingen tastaturfelle (2.1.2)`}</h3>
    <h3>{`Hoppe over blokker (2.4.1)`}</h3>
    <h3>{`Sidetitler (2.4.2)`}</h3>
    <h3>{`Fokusrekkefølge (2.4.3)`}</h3>
    <h3>{`Formål med lenke i kontekst (2.4.4)`}</h3>
    <h3>{`Flere måter (2.4.5)`}</h3>
    <h3>{`Overskrifter og ledetekster (2.4.6)`}</h3>
    <h3>{`Synlig fokus (2.4.7)`}</h3>
    <h2>{`Prinsipp 3: Forståelig`}</h2>
    <h3>{`Språk på siden (3.1.1)`}</h3>
    <h3>{`Språk på deler av innhold (3.1.2)`}</h3>
    <h3>{`Fokus (3.2.1)`}</h3>
    <h3>{`Inndata (3.2.2)`}</h3>
    <h3>{`Konsekvent navigering (3.2.3)`}</h3>
    <h3>{`Konsekvent identifikasjon (3.2.4)`}</h3>
    <h3>{`Identifikasjon av feil (3.3.1)`}</h3>
    <h3>{`Ledetekster eller instruksjoner (3.3.2)`}</h3>
    <h3>{`Forslag ved feil (3.3.3)`}</h3>
    <h3>{`Forhindring av feil (3.3.4)`}</h3>
    <h2>{`Prinsipp 4: Robust`}</h2>
    <h3>{`Parsing/oppdeling (4.1.1)`}</h3>
    <h3>{`Navn, rolle, verdi (4.1.2)`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      